<script setup>
import navItems from '@/navigation/vertical'
import { themeConfig } from '@themeConfig'

// Components
import Footer from '@/layouts/components/Footer.vue'
import NavbarThemeSwitcher from '@/layouts/components/NavbarThemeSwitcher.vue'
import UserProfile from '@/layouts/components/UserProfile.vue'
import NavBarI18n from '@core/components/I18n.vue'

// @layouts plugin
import { VerticalNavLayout } from '@layouts'

const filteredNavItems = navItems.filter((navItem) => checkRole(navItem.roles))
</script>

<template>
	<VerticalNavLayout :nav-items="filteredNavItems">
		<template #navbar="{ toggleVerticalOverlayNavActive }">
			<div class="d-flex h-100 align-center">
				<IconBtn
					id="vertical-nav-toggle-btn"
					class="ms-n3 d-lg-none"
					@click="toggleVerticalOverlayNavActive(true)">
					<VIcon size="26" icon="tabler-menu-2" />
				</IconBtn>

				<div>
					<VIcon icon="tabler-access-point" />
					{{ branch().title }}
				</div>

				<VSpacer />

				<NavbarThemeSwitcher />

				<NavBarI18n
					v-if="themeConfig.app.i18n.enable && themeConfig.app.i18n.langConfig?.length"
					:languages="themeConfig.app.i18n.langConfig" />

				<UserProfile />
			</div>
		</template>

		<slot />

		<template #footer>
			<Footer />
		</template>

		<!-- <TheCustomizer /> -->
	</VerticalNavLayout>
</template>
