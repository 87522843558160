export default [
	{
		title: 'menu.home',
		to: { name: 'index' },
		icon: { icon: 'tabler-smart-home' },
		roles: ['pm', 'teamlead', 'supervision'],
	},
	{
		title: 'menu.projects',
		to: { name: 'projects' },
		icon: { icon: 'tabler-file' },
		roles: ['pm', 'teamlead'],
	},
	{
		title: 'menu.employees',
		to: { name: 'employees' },
		icon: { icon: 'tabler-users' },
		roles: [],
	},
	{
		title: 'menu.objects',
		to: { name: 'objects' },
		icon: { icon: 'tabler-building-arch' },
		roles: ['pm', 'teamlead', 'supervision'],
	},
	{
		title: 'menu.profile',
		to: { name: 'profile' },
		icon: { icon: 'tabler-user' },
		roles: ['pm', 'teamlead', 'supervision'],
	},
	{
		title: 'menu.wiki',
		to: { name: 'wiki' },
		icon: { icon: 'tabler-news' },
		roles: ['pm', 'teamlead'],
	},
	{
		title: 'menu.settings',
		to: { name: 'settings' },
		icon: { icon: 'tabler-settings' },
		roles: [],
	},
]
